import { connect } from 'react-redux'
import Component from './component'
import { sIsMD } from '../../redux/modules/browser'
import { closeModal } from '../../redux/modules/mylistCheckout'

const WithData = connect(
  state => ({
    isMD: sIsMD(state),
    showModal: state.mylistCheckout.showModal,
    isPPC: state.mylistCheckout.isPPC,
  }),
  dispatch => ({ closeModal: () => dispatch(closeModal()) })
)(Component)

export default WithData
