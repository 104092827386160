import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import DetailedRequestForm from '../Forms/DetailedRequest'
import './styles.scss'

const MyListCheckout = ({ showModal, closeModal }) => {
  useEffect(() => {
    document.documentElement.classList[showModal ? 'add' : 'remove'](
      'no-scroll'
    )
  }, [showModal])

  if (!showModal) return <div />

  const closeButton = (
    <span onClick={closeModal} className="material-icons">
      close
    </span>
  )

  return (
    <div
      className={`dhsv_mylist_checkout`}
      onClick={e => {
        if (e.target === e.currentTarget) {
          closeModal()
        }
      }}
    >
      <div className="container">
        <DetailedRequestForm
          headerRightElement={closeButton}
          formVariant={'list'}
        />
      </div>
    </div>
  )
}

MyListCheckout.propTypes = {
  showModal: PropTypes.bool,
  closeModal: PropTypes.func,
}

export default MyListCheckout
